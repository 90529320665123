import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Grid,
  extendTheme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';

const colors = {
  brand: {
    900: '#D34848',
    800: '#FFFA67',
    700: '#FFCD60',
  },
}

const theme = extendTheme({   
  colors,
  styles: {
    global: () => ({
      body: {
        bg: "black",
      },
    }),
  },
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid color="red" minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Text>
              Website coming soon. Please visit our youtube channel
            </Text>
            <Link
              color="brand.700"
              href="https://youtube.com/@perufoodart"
              fontSize="2xl"
              target="_blank"
              rel="noopener noreferrer"
            >
            youtube.com/@perufoodart
            </Link>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
